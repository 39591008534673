import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import CategoryContent from '../components/CategoryContent';

const PlatformPage = ({ data }) => {
  return (
    <Fragment>
      <SEO meta={data.page.seoMetaTags} />
      <CategoryContent page={data.page} />
    </Fragment>
  );
};

PlatformPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const templateQuery = graphql`
  query($slug: String!) {
    page: datoCmsPlatformPage(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      smallTitle
      introText
      contentBlocks {
        id
        title
        content
      }
      featureDescription
      featureBlocks {
        title
        content
      }
    }
  }
`;

export default PlatformPage;
